.adm-tag {
  --border-radius: var(--adm-tag-border-radius, 2px);
  padding: 2px 4px;
  font-size: 11px;
  line-height: 1;
  font-weight: normal;
  background: var(--background-color);
  color: var(--text-color);
  display: inline-block;
  white-space: nowrap;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
}
.adm-tag-round {
  border-radius: 100px;
}
