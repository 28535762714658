.adm-dialog {
  --z-index: var(--adm-dialog-z-index, 1000);
  position: fixed;
  z-index: var(--z-index);
}
.adm-dialog .adm-dialog-mask {
  z-index: 0;
}
.adm-dialog-wrap {
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 75vw;
  max-width: 500px;
  transform: translate(-50%, -50%);
}
.adm-dialog-body {
  width: 100%;
  max-height: 70vh;
  font-size: 14px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.adm-dialog-body > * {
  flex: none;
}
.adm-dialog-body > .adm-dialog-content {
  flex: auto;
}
.adm-dialog-body:not(.adm-dialog-with-image) {
  padding-top: 20px;
}
.adm-dialog-body .adm-dialog-image-container {
  margin-bottom: 12px;
  max-height: 40vh;
}
.adm-dialog-body .adm-dialog-header {
  margin-bottom: 8px;
  padding: 0 12px;
}
.adm-dialog-body .adm-dialog-title {
  margin-bottom: 8px;
  padding: 0 12px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}
.adm-dialog-body .adm-dialog-content {
  padding: 0 12px 20px;
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 15px;
  line-height: 1.4;
  color: #333;
}
.adm-dialog-body .adm-dialog-content-empty {
  padding: 0;
  height: 12px;
}
.adm-dialog-body .adm-dialog-footer {
  user-select: none;
}
.adm-dialog-body .adm-dialog-footer .adm-dialog-action-row {
  display: flex;
  align-items: stretch;
  border-top: 0.5px solid var(--adm-border-color);
}
.adm-dialog-body .adm-dialog-footer .adm-dialog-action-row > * {
  flex: 1;
}
.adm-dialog-body .adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button {
  padding: 10px;
  font-size: 18px;
  line-height: 25px;
  border-radius: 0;
  border-right: solid 0.5px var(--adm-border-color);
}
.adm-dialog-body .adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button-bold {
  font-weight: bold;
}
.adm-dialog-body .adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button:last-child {
  border-right: none;
}
.adm-dialog-image-container {
  overflow-y: auto;
}
