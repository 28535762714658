.adm-form {
  --border-inner: solid 1px var(--adm-border-color);
  --border-top: solid 1px var(--adm-border-color);
  --border-bottom: solid 1px var(--adm-border-color);
  ---border-inner: var(--border-inner);
  ---border-top: var(--border-top);
  ---border-bottom: var(--border-bottom);
}
.adm-form .adm-list.adm-list {
  --padding-left: 16px;
  --padding-right: 12px;
  --border-inner: var(---border-inner);
  --border-top: var(---border-top);
  --border-bottom: var(---border-bottom);
}
.adm-form .adm-form-footer {
  padding: 20px 12px;
}
.adm-form-list-operation {
  text-align: center;
  color: #1677ff;
}
.adm-form-item + .adm-form-item {
  border-top: none;
}
.adm-form-item-label {
  display: block;
  height: 100%;
  line-height: 1.5;
  box-sizing: border-box;
  position: relative;
  color: #666666;
}
.adm-form-item-label .adm-form-item-required-asterisk {
  position: absolute;
  left: -0.6em;
  top: 0;
  font-family: SimSun, sans-serif;
  color: var(--adm-color-danger);
  user-select: none;
}
.adm-form-item-label .adm-form-item-required-text {
  margin-left: 4px;
  color: var(--adm-color-weak);
}
.adm-form-item-label-help {
  margin-left: 4px;
  cursor: pointer;
}
.adm-form-item-child {
  display: flex;
}
.adm-form-item-child-position-normal {
  justify-content: normal;
}
.adm-form-item-child-position-normal > * {
  flex: auto;
}
.adm-form-item-child-position-right {
  justify-content: flex-end;
}
.adm-form-item-child-position-right > * {
  flex: none;
}
.adm-form-item-feedback-error {
  color: var(--adm-color-danger);
  margin-top: 4px;
}
.adm-form-item-feedback-warning {
  color: var(--adm-color-warning);
  margin-top: 4px;
}
.adm-form-item.adm-form-item-hidden {
  display: none;
}
.adm-form-item.adm-form-item-horizontal.adm-list-item {
  --align-items: stretch;
  --prefix-width: 6.8em;
}
.adm-form-item.adm-form-item-horizontal .adm-list-item-content-prefix {
  padding-top: 12px;
  padding-bottom: 12px;
}
.adm-form-item.adm-form-item-horizontal .adm-list-item-content-extra {
  align-self: center;
}
.adm-form-item.adm-form-item-vertical .adm-form-item-label {
  font-size: 15px;
  margin-bottom: 4px;
}
