.adm-text-area {
  --font-size: 17px;
  --color: var(--adm-color-text);
  --placeholder-color: var(--adm-color-light);
  --disabled-color: var(--adm-color-weak);
  --text-align: left;
  --count-text-align: right;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.adm-text-area-element {
  font-family: var(--adm-font-family);
  resize: none;
  flex: auto;
  display: block;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  margin: 0;
  color: var(--color);
  font-size: var(--font-size);
  line-height: 1.5;
  background: transparent;
  border: 0;
  outline: none;
  appearance: none;
  min-height: 1.5em;
  text-align: var(--text-align);
}
.adm-text-area-element::placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
}
.adm-text-area-element:-webkit-autofill {
  background-color: transparent;
}
.adm-text-area-element:disabled {
  color: var(--disabled-color);
  cursor: not-allowed;
  opacity: 1;
  -webkit-text-fill-color: var(--disabled-color);
}
.adm-text-area-element:read-only {
  cursor: default;
}
.adm-text-area-element:invalid {
  box-shadow: none;
}
.adm-text-area-element::-ms-clear {
  display: none;
}
.adm-text-area-element[readonly] {
  pointer-events: none;
}
.adm-text-area-count {
  text-align: var(--count-text-align);
  color: var(--adm-color-weak);
  font-size: 17px;
  padding-top: 8px;
}
