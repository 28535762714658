:root {
  --adm-color-primary: #1677ff;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-white: #ffffff;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-border-color: #eeeeee;
  --adm-font-size-main: 13px;
  --adm-color-text: #333333;
  --adm-hd: 1;
  --adm-font-family: -apple-system, blinkmacsystemfont, 'Helvetica Neue',
    helvetica, segoe ui, arial, roboto, 'PingFang SC', 'miui',
    'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
}
:root {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  color: var(--adm-color-text);
  font-size: var(--adm-font-size-main);
  font-family: var(--adm-font-family);
}
a,
button {
  cursor: pointer;
}
a {
  color: var(--adm-color-primary);
  transition: opacity ease-in-out 0.2s;
}
a:active {
  opacity: 0.8;
}
.adm-plain-anchor {
  color: unset;
  transition: none;
}
.adm-plain-anchor:active {
  opacity: unset;
}
body.adm-overflow-hidden {
  overflow: hidden !important;
}
div.adm-px-tester {
  --size: 1;
  height: calc(var(--size) / 2 * 2px);
  width: 0;
  position: fixed;
  right: -100vw;
  bottom: -100vh;
  user-select: none;
  pointer-events: none;
}
